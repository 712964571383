import React from 'react'
import { FaCapsules, FaHospital, FaUserNurse } from 'react-icons/fa'

const BackGround = () => {
  return (
    <>
    	{/* <!--Start Background Animation Body--> */}
		<div className="area">
			<ul className="circles text-indigo-100">
				<li>
					<div className='flex justify-center items-center w-full h-full'>
					<FaUserNurse className='p-1 absolute text-5xl'/>
					</div>
				</li>
				<li className='relative'></li>
				<li className='relative'></li>
				<li>
				<div className='flex justify-center items-center w-full h-full'>
					<FaCapsules className='p-1 absolute text-4xl'/>
					</div>
				</li>
				<li className='relative'></li>
				<li>
					<div className='flex justify-center items-center w-full h-full'>
					<FaHospital className='p-1 absolute text-6xl'/>
					</div>
				</li>
				<li className='relative'></li>
				<li className='relative'></li>
				<li className='relative'></li>
				<li className='relative'></li>
			</ul>
		</div>
		{/* <!--End Background Animation Body--> */}
    </>
  )
}

export default BackGround