import { Description, Field, Input, Label, Textarea } from "@headlessui/react";
import React from "react";
import clsx from 'clsx';

const Fields = ({label,description,type,id}) => {
  return (
    <>
      <Field className="mt-3">
        <Label className="text-sm/6 font-medium text-white">{label}</Label>
        <Description className="text-xs text-white/50">
        {description}
        </Description>
{type !== "textarea" ?<Input
        required
        name={id}
        type={type}
          className={clsx(
            "mt-3 block w-full rounded-lg border border-gray-400 bg-white/5 py-1.5 px-3 text-sm/6 text-white",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
          )}
        />:
        <Textarea
        name={id}
        required
        className={clsx(
            'mt-3 block w-full resize-none rounded-lg border border-gray-400 bg-white/5 py-1.5 px-3 text-sm/6 text-white',
            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
          )}
          rows={5}
        />
        }
      </Field>
    </>
  );
};

export default Fields;
