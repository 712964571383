import { FastDeliveryImg, FastPackingImg, HandShakeImg } from "../../assets";

const incentives = [
  {
    name: "Traitement rapide",
    imageSrc: FastPackingImg,
    description: "Traitement rapide des demandes et requêtes entrantes.",
  },
  {
    name: "Service client",
    imageSrc: HandShakeImg,
    description: "assistance de nos clients avant, pendant et après l'achat..",
  },
  {
    name: "Livraison gratuite",
    imageSrc: FastDeliveryImg,
    description: "Livraison gratuite partout au Maroc",
  },
];

const Incentives = () => {
  return (
    <div className="py-16 md:py-6 bg-primary md:pb-20 ">
      <div className="container">
      <div className="mx-auto max-w-xl lg:max-w-none">
            <div className="text-center">
              <h2 className="text-lg font-semibold text-secondary-50">
                Les avantages de notre entreprise
              </h2>
            </div>
            <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-y-10 gap-x-8 sm:max-w-none lg:grid-cols-3">
              {incentives.map((incentive) => (
                <div
                  key={incentive.name}
                  className="text-center sm:flex sm:text-left lg:block lg:text-center"
                >
                  <div className="sm:flex-shrink-0">
                    <div className="flow-root">
                      <img
                        className="mx-auto h-16 w-16 bg-white rounded-lg p-2"
                        src={incentive.imageSrc}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-sm font-medium text-secondary-100">
                      {incentive.name}
                    </h3>
                    <p className="mt-2 text-sm text-secondary-200">
                      {incentive.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>      </div>
    </div>
  );
};
export default Incentives;
