import { Element } from "react-scroll";
import Header from "./Components/Sections/Header";
import CTA from "./Components/Sections/CTA";
import Features from "./Components/Sections/Features";
import Footer from "./Components/Sections/Footer";
import Hero from "./Components/Sections/Hero";
import Incentives from "./Components/Sections/Incentives";
import Products from "./Components/Sections/Products";
import { app, Logo, navigation, contacts, social, Title } from "./data";
import BackGround from "./Components/BackGround";
import ContactForm from "./Components/Sections/ContactForm";
import { useState } from "react";

function App() {

  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <BackGround />
      <Element name="home" />
      <Header siteName={app.name} navigation={navigation} logo={Logo} />
      <Hero open={setIsOpen}/>
      <Element name="about">
        <Features Title={Title} />
      </Element>
      <Element name="products">
        <Products />
      </Element>
      <CTA open={()=>setIsOpen(true)}/>
      <Incentives />
      <Footer
        app={app}
        navigation={navigation}
        logo={Logo}
        contacts={contacts}
        social={social}
      />
      {isOpen &&<ContactForm close={()=>setIsOpen(false)} isOpen={isOpen} />}
    </>
  );
}

export default App;
