import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-scroll";
import { Logo, whatsapp } from "../../data";
import { FaWhatsapp } from "react-icons/fa";

const Header = ({ logo, siteName, navigation }) => {
  return (
    <header className="fixed z-40 w-full top-0">
      <Popover className="bg-secondary-50">
        <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link
              spy={true}
              smooth={true}
              duration={500}
              to="home"
              className="cursor-pointer"
            >
              <span className="sr-only">{siteName}</span>
              {Logo}
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            {navigation.map((item, idx) => (
              <Link
                key={`header-nav-${idx}`}
                spy={true}
                smooth={true}
                duration={500}
                to={item.href}
                className="text-base font-medium text-neutral-800 hover:text-primary-600 cursor-pointer"
              >
                {item.name}
              </Link>
            ))}
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <a
              target="blank"
              href="https://wa.me/message/RS5BRO2A36FNA1?src=qr"
              className="flex items-center gap-1 whitespace-nowrap text-base font-medium text-green-500 hover:text-primary-600"
            >
              <FaWhatsapp /> whatsapp
            </a>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <Link
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="home"
                    className="cursor-pointer"
                  >
                    {Logo}
                  </Link>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="space-y-1 px-2">
                  {navigation.map((item, idx) => (
                    <Link
                      key={`header-mobile-nav-${idx}`}
                      spy={true}
                      smooth={true}
                      duration={500}
                      to={item.href}
                      className="cursor-pointer block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:text-primary-600 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <div className="mt-6">
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Pour plus d'information!{" "}
                    <a
                    target="blank"
                      href={`https://wa.me/message/RS5BRO2A36FNA1?src=qr`}
                      className="flex items-center gap-2 justify-center text-green-500 hover:text-primary-600"
                    >
                      <FaWhatsapp /> whatsapp
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};
export default Header;
