import { CheckCircleIcon } from "@heroicons/react/20/solid";
import BackGround from "../BackGround";
import { cardioImg, equipementImg } from "../../assets";
const assortments = [
  "Excellence",
  "Meilleure qualité de service",
  "Satisfaction du client",
  "Personnalisation et Flexibilité",
  "Respect des normes internationales.",
  "Innovation",
];

const Features = ({ Title }) => {
  return (
    <>
      <div className="section space-y-5 mt-8">
        <div className="relative pt-10 sm:pt-16 bg-primary border-t-2 border-blue-400 shadow-xl ">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8 -mb-8">
            <div className="mx-auto text-secondary-50 max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:pt-10 lg:px-0">
              <div>
                <div className="space-y-4">
                  <h2 className="section-subtitle md:pt-5">
                    KAMEDCARD <br></br> au <br></br> Maroc
                  </h2>
                  <span className="font-bold text-secondary-100">
                    Qui sommes-nous:
                  </span>
                  <p className="text-secondary-200">
                    {Title} a été créée en 2022 PAR 3 ex commerciaux de
                    l'industrie pharmaceutique.<br></br>
                    L'objectif de {Title}, importer, exporter et distribuer les
                    dispositifs médicaux de cardiologie de qualité.
                  </p>
                  <span className="text-secondary-200 font-bold">
                    {/* Partenariats stratégiques et avancées technologiques: */}
                  </span>
                  <p className="text-secondary-200">
                    {Title} est le véritable partenaire Marocain dans la
                    distribution des dispositifs médicaux de la cardiologie
                    interventionnelle et périphérique interventionnel.
                    {Title} importe et distribue une gamme de dispositifs
                    innovants comprenant une large gamme de ballons,
                    d’endoprothèse et de cathéters spéciaux haute performance.
                    Des prix compétitifs. Des commerciaux expérimentés,
                    performants, avec un large portefeuille de prospects sur
                    tout le territoire marocain.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:block -mb-28 mt-12 sm:mt-16 lg:mt-0">
              <div className="h-64 px-6 w-full lg:px-0 lg:h-full lg:w-full">
                <img
                  className="h-[80%] w-full object-cover object-center rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                  src={cardioImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <BackGround />
          <div className=" pt-10 -mb-20 md:-mb-48 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-12 lg:px-0">
              <div>
                <div>
                  <h2 className="section-subtitle">Nos valeurs:</h2>
                  <ul className="mt-6 space-y-6">
                    {assortments.map((assort, idx) => (
                      <li
                        key={`assortiment-${idx}`}
                        className="flex items-center lg:col-span-1 gap-2 space-y-4 px-8"
                      >
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 mt-3 text-green-400 bg-white rounded-full"
                            aria-hidden="true"
                          />
                        </div>
                        <p className="ml-3 text-base text-gray-100">{assort}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <div className="px-6 h-64 w-full lg:px-0 lg:h-full lg:w-full">
                <img
                  className="h-[80%] object-[top] w-full object-cover rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                  src={equipementImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="py-14 relative bg-primary-300">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
              <div>
                <div className="">
                  <h2 className="section-subtitle">
                    Comment acheter de manière rentable son matériel médical ?
                  </h2>
                  <p className="section-text">
                    L'avantage ne s'exprime pas seulement dans l'obtention d'une
                    qualité décente à faible coût. Pour de nombreuses cliniques,
                    un facteur tel que le traitement rapide des commandes, la
                    livraison par diverses sociétés de transport sont
                    importants.
                  </p>
                  <p className="section-text">
                    Les grands établissements de santé, les centres de
                    diagnostic et les établissements médicaux d'urgence tentent
                    d'acheter des consommables en grande quantité, de sorte que
                    les gants, les masques, les draps et serviettes non tissés
                    jetables et les conteneurs pour l'élimination des déchets
                    dangereux soient toujours en réserve.
                  </p>
                  <p className="section-text">
                    Les spécialistes de la société{" "}
                    <span className="text-primary-900 font-bold">WC</span>
                    <span className="text-secondary-600">MEDICALE</span>{" "}
                    consacrent beaucoup de temps à la formation de propositions.
                    Par conséquent, il est plus facile pour les cabinets privés
                    et les petites cliniques spécialisées au Maroc d'acheter du
                    matériel médical en passant une commande par e-mail ou
                    téléphone.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="px-6 h-64 w-full lg:px-0 lg:h-full lg:w-full">
                <img
                  className="h-full w-full object-cover object-center rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                  src="https://img.freepik.com/free-photo/preparation-blood-test-by-female-doctor-medical-uniform-table-white-bright-room-nurse-pierces-patient-s-arm-vein-with-needle-blank-tube_657921-883.jpg?w=1380&t=st=1667171065~exp=1667171665~hmac=be315d1cae4b97135ffe96376c169f5e96f32d1c00f4262677f775eee942635d"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Features;
