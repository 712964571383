import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Fields from "./Fields";
import { FaEnvelope } from "react-icons/fa";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from "react";
import { FaCircleNotch } from "react-icons/fa6";

const ContactForm = ({close,isOpen}) => {
  const form = useRef()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ response, setResponse ] = useState('')

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true)
    emailjs
      .sendForm('service_r7jgcpr', 'template_zdl1amc', form.current, {
        publicKey: 'a9JXH76eB5eh43Lpd',
      })
      .then(
        () => {
    setIsLoading(false)
    setResponse('Message envoyer merci :D !');
        },
        (error) => {
    setIsLoading(false)
    setResponse("Erreur d'envoie", error.text);
        },
      );
  };

  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="w-full max-w-md rounded-xl bg-gray-500/50 p-6 backdrop-blur-2xl">
                  <DialogTitle
                    as="h3"
                    className="text-base/7 font-medium text-white"
                  >
                    Contactez-nous
                  </DialogTitle>
                  <form ref={form} onSubmit={sendEmail}>
                  <div className="w-full max-w-md px-4">
                    <Fields
                    id={"Nom dentreprise"}
                      label={"Nom d'entreprise"}
                      type={"text"}
                      description={"Nom d'entreprise ou bien d'association."}
                    />
                    <Fields
                    id={"Email"}
                      label={"Email"}
                      type={"email"}
                      description={"Email professionnel dans que voulez vous reçevez votre réponse."}
                    />
                    <Fields
                    id={"Numero de telephone"}
                      label={"Numéro de télephone"}
                      type={"number"}
                      description={"Numéro de téléphone professionnel dans où nous peut vous contacter."}
                    />
                    <Fields
                    id={"Message"}
                      label={"Message"}
                      type={"textarea"}
                      description={"votre message"}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-4">
                    <Button disabled={isLoading} type="submit" className="disabled:opacity-50 flex items-center gap-1 text-white ring py-1 px-3 rounded-sm bg-blue-500 hover:bg-transparent">
                     {!isLoading ?
                     <>
                     <FaEnvelope className="text-sm"/>
                     Confirmer
                     </>:
                     <>
                     <FaCircleNotch className="animate-spin" /> en cours... 
                     </>
                     }
                    </Button>
                    <Button
                      disabled={isLoading}
                      className="disabled:opacity-50 inline-flex items-center gap-2 py-1.5 px-3 text-sm/6 font-semibold text-gray-700 hover:text-white rounded-xl focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                      onClick={close}
                    >
                      fermer
                    </Button>
                  </div>
                    <p className="my-2 text-amber-500">{response??''}</p>
                  </form>

                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
export default ContactForm;
