import { heroImg } from "../../assets";
const Hero = ({open}) => {
  return (
    <>
      <div className="container h-[80vh] sm:h-[78vh] pt-24 mt-16 -mb-16 pb-24 sm:pb-0 sm:mt-20 sm:-mb-28">
        <div className="relative h-full sm:h-full overflow-hidden rounded-lg">
          <div className="absolute inset-0">
            <img
              src={heroImg}
              alt=""
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="relative flex h-full bg-blue-800 bg-opacity-50 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
            <div className="relative mx-auto my-auto flex max-w-3xl flex-col items-center text-center">
              <h1 className="text-center text-4xl tracking-tight sm:text-5xl lg:text-6xl">
                <span className="text-primary font-bold">KAMED</span>
                <span className="text-secondary-50 font-normal">CARD</span>
              </h1>
              <p className="mt-3 text-xl text-white">
              Import, Export, Distribution
              </p>
              <button
                onClick={()=>open(true)}
                className="mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:text-primary-600 sm:w-auto"
              >
                Contactez-nous
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
