import { Link } from "react-scroll";

const today = new Date();
const year = today.getFullYear();

// import { Link as ScrollLink } from "react-scroll";
function Footer({ app, logo, navigation, contacts, social }) {
  return (
    <>
      <footer className="bg-neutral-900">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-8">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:col-span-2">
              <div className="">
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="home"
                  className="cursor-pointer"
                >
                  <span className="sr-only">{app.name}</span>
                  {logo ? (
                    logo
                  ) : (
                    <span className="font-extrabold text-xl text-primary">
                      {app.name}
                    </span>
                  )}
                </Link>
              </div>
              <div className="md:order-none">
                <h3 className="text-sm font-semibold text-neutral-400 tracking-wider uppercase">
                  Menu
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.map((nav) => (
                    <li key={"footer-menu-" + nav.name}>
                      <Link
                        spy={true}
                        smooth={true}
                        duration={500}
                        to={nav.href}
                        activeClass="active"
                        className="cursor-pointer text-base text-neutral-300 hover:text-white"
                      >
                        {nav.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mt-8 sm:mt-0">
              <h3 className="text-sm font-semibold text-neutral-400 tracking-wider uppercase">
                Contacts
              </h3>
              <ul className="mt-4 space-y-4">
                {contacts.map((contact) => (
                  <li
                    key={"footer-contacts-" + contact.title}
                    className="flex space-x-2"
                  >
                    <span>
                      <contact.icon className="w-5 h-5 text-secondary-500" />
                    </span>
                    <a
                      href={contact.href}
                      className="text-base text-neutral-300 hover:text-white uppercase"
                    >
                      {contact.description}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-neutral-700 pt-8 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              {/* {social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-neutral-400 hover:text-white"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))} */}
            </div>
            <p className="mt-8 text-base text-neutral-400 md:mt-0 md:order-1">
              &copy; {year}{" "}
              <a
                href="/"
                className="text-neutral-100 hover:text-white hover:underline"
              >
                {app.name}
              </a>
              . Powered by{" "}
              <a
                href="https://www.linkedin.com/in/el-mehdi-el-kasbari-859231217/"
                className="text-neutral-100 hover:text-white hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                EL MEHDI EL KASBARI
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
