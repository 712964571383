import { CTAImg } from "../../assets";

const CTA = ({open}) => {
  return (
    <div className="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src={CTAImg}
          alt=""
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="container">
        <div className="relative flex max-w-3xl flex-col items-start">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">Pour plus d'information ?</span>
            <span className="block text-primary-600">
              N'hésitez pas à nous contacter.
            </span>
          </h2>
          <p className="mt-3 text-xl text-gray-700">
            Une personne de notre équipe se fera un plaisir de communiquer avec
            vous.
          </p>
          <button
          onClick={()=>open(true)}
            className="mt-8 block rounded-md border border-transparent bg-primary py-3 px-8 text-base font-medium text-white hover:bg-secondary-600 sm:w-auto"
          >
            Contactez-nous
          </button>
        </div>
      </div>
    </div>
  );
};
export default CTA;
