import { Tab } from "@headlessui/react";
import {
  prd1,
  prd10,
  prd11,
  prd3,
  prd4,
  prd5,
  prd8,
} from "../../assets";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const categories = [
  {
    name: "les stents des artères coronaires et périphériques",
    products: [
      {
        image: prd1,
      },
      {
        image: prd3,
      },
      {
        image: prd4,
      },
      {
        image: prd8,
      },
    ],
  },

  {
    name: "microcathéter coronaire",
    products: [
      {
        image: prd10
      },
      {
        image: prd11
      },
    ],
  },

  {
    name: "les ballons de dilatations coronaires et périphériques",
    products: [
      {
        image: prd5
      },
    ],
  },

];

const Products = () => {
  return (
    <div className="py-20 -mt-16 bg-primary">
      <div className="container text-center">
        <h2 className="section-subtitle">Nos produits</h2>
        <Tab.Group>
          <div className="mx-auto mt-12">
            <Tab.List className="flex mx-auto p-1 gap-2 rounded-xl bg-gradient-to-b from-primary to-blue-400">
              {categories.map((category, idx) => (
                <Tab
                  key={`category-${idx}`}
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-secondary-50",
                      "ring-white ring-opacity-60 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-primary shadow"
                        : "text-secondary-50 hover:bg-white/60 hover:text-neutral-900"
                    )
                  }
                >
                  <h3 className="mt-3 text-xs md:text-lg font-bold text-secondary-50">
                    {category.name}
                  </h3>
                </Tab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels className="mt-2">
            <div className="flex justify-center max-w-7xl overflow-hidden mt-12">
              {categories.map((category, catIdx) => (
                <Tab.Panel
                  key={`products-${catIdx}`}
                  className="flex flex-wrap justify-around  w-full gap-4"
                >
                  {category.products.map((product, prdIdx) => (
                    <div
                      key={`product-${prdIdx}`}
                      className="group w-2/5 md:w-1/5 text-md text-center border border-neutral-200 rounded-xl overflow-hidden px-4 py-6"
                    >
                      <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-neutral-100">
                        <img
                          src={product.image}
                          alt={product.name}
                          className="h-full w-full object-cover object-center"
                        />
                      </div>
                    </div>
                  ))}
                </Tab.Panel>
              ))}
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
export default Products;
